<template>
  <mobile-download v-if="mobile" />
  <div
    v-else
    class="auth-wrapper auth-v1 px-2"
  >
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <div
          class="connection__light float-right"
          :class="!connection ? 'is_connection_failed' : ''"
        />
        <b-link class="brand-logo">
          <h1
            class="main-logo"
            @click="$router.push({name: 'home'}).catch(() => {})"
          >
            <img
              :src="Logo"
              class="img-fluid"
              alt="logo"
            >
          </h1>
        </b-link>

        <b-card-title class="mb-1">
          Login Form
        </b-card-title>
        <!-- <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text> -->

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="loginUser"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="login.email"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'reset-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="login.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
                @click="addRememberMe"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <div class="auth_button_view">
              <b-button
                variant="outline-primary"
                type="submit"
                :disabled="invalid"
              >
                Login in
              </b-button>
            </div>
            <!-- submit button -->

          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'register'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import checkScreenOsMixins from '@/mixins/checkScreenOsMixins.js'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import MobileDownload from '../../MobileDownload.vue'
import Logo from '../../assets/images/logo/logo.png'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    MobileDownload,
  },
  mixins: [togglePasswordVisibility, checkScreenOsMixins],
  data() {
    return {
      Logo,
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      login: {
        email: '',
        password: '',
        remember_me: false,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    connection() {
      return this.$store.state.app.connection
    },
  },
  created() {
    this.checkScreen()
    window.addEventListener('load', this.checkScreen)
  },
  beforeMount() {
    this.checkScreen()
  },
  mounted() {
    this.checkScreen()
  },
  methods: {
    addRememberMe() {
      this.login.remember_me = !this.login.remember_me
    },
    loginUser() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/loginUser', this.login)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
